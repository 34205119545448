/** Extra columns spacing **/
.extra-spacing:not(.col-12), :not(.col12) > .e-content {
  padding-right: 1rem;

  @include breakpoint(md) {
    padding-right: 0;
  }
}

/** Breadcrumbs styling **/
#breadcrumbs {
  padding-left: 0;
  display: flex;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: 1rem;


  i {
    display: none;
  }

  span, a {
    padding: 0 0.5rem;
    &:first-child {
      padding-left: 0;
    }
  }

  span, a {
    &:not(:first-child)::before {
      color: #e7e9ed;
      content: "/";
      padding-right: 1rem;
    }
  }
}

/** Blog Listing **/
.blog-listing {

  .bricklayer-column {
    padding-left: 0px;
    padding-right: 25px;

  }
  .card {
    margin-bottom: 25px;
    border: 0;
    box-shadow: 0 10px 45px -9px rgba(0,0,0,0.1);
  }

  .card-footer {
    text-align: right;
  }

  .blog-date {
    font-size: 13px;
  }

  .label {

  }
}

/** Blog Item **/
.content-title {
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 0.5rem;
  }
}

.label {
  font-size: 12px;
  text-transform: uppercase;
}

/** Pagination **/
ul.pagination {
  justify-content: center;
}

.prev-next {
  margin-top: 4rem;
}

/** Sidebar specific tweaks **/
#sidebar {

  ul.related-pages {
    box-shadow: none;
    padding: 0;

    li {
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom: 0;
      }
    }

  }

  ul.archives {
    list-style: none;
    margin-left: 0;

    .label {
      vertical-align: text-top;
    }
  }
}

/** Blog Card specific tweaks **/
@mixin cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}
.blog-card{
  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    a {
      color: $light-color;
    }
    span {
      position: absolute;
      left: 50%;
      top: 0;
      font-size: 10px;
      font-weight: 700;
      font-family: 'Open Sans';
      transform: translate(-50%, 0);
    }
  }
  .wrapper {
    background-color: $light-color;
    min-height: 470px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($dark-color, 0.3), 0 15px 12px rgba($dark-color, 0.2);
    &:hover {
      .data {
        transform: translateY(0);
      }
    }
  }
  .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary-color;
    color: $light-color;
    padding: 0.8em;
    span {
      display: block;
      text-align: center;
    }
    .day {
      font-weight: 700;
      font-size: 24px;
      text-shadow: 2px 3px 2px rgba($dark-color, 0.18);
    }
    .month {
      text-transform: uppercase;
    }
    .month,
    .year {
      font-size: 12px;
    }
  }
  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;
    opacity: 0.7;
    background-color: $dark-color;
    .content {
      padding: 1em;
      position: relative;
      z-index: 1;
    }
  }
  .menu-content {
    float: right;
    li {
      margin: 0 5px;
      position: relative;
    }
    span {
      transition: all 0.3s;
      opacity: 0;
    }
  }
  .link {
    display: block;
    width: 100px;
    margin: 2em auto 1em;
    text-align: center;
    font-size: 12px;
    color: $light-color;
    line-height: 1;
    position: relative;
    font-weight: 700;
    &::after {
      content: '\2192';
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.3s;
    }
    &:hover {
      &::after {
        transform: translate(5px, -50%);
        opacity: 1;
      }
    }
  }
  .author {
    font-size: 12px;
  }
  .title {
    margin-top: 10px;
  }
  .text {
    height: 70px;
    margin: 0;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .menu-content {
    transform: translateY(-60px);
  }
}